import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Alert, Form, Input, Label, Container, Row, Col } from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import axios from "axios";

const Login = (props) => {
  document.title = "Login | Agenxy";
  let history = useHistory();

  const [error, setError] = useState(null);
  const [responseBody, setResponseBody] = useState([]);

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setResponseBody({ ...responseBody, [name]: value });
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    axios({
      url: "https://agenxyio.pythonanywhere.com/login-admin",
      method: "POST",
      headers: { "Content-type": "application/json; charset=UTF-8" },
      data: responseBody,
    })
      .then((res) => {
        setError(null);
        localStorage.setItem("authData", JSON.stringify(res["data"]));
        history.push("/all-agencys");
      })
      .catch((err) => {
        setError(err["response"]["data"]["error"]);
      });
  };

  return (
    <React.Fragment>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0 flex justify-content-center justify-center">
            <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">Welcome Agenxy Admin !</h5>
                      </div>
                      <Form
                        className="custom-form mt-4 pt-2"
                        onSubmit={(e) => onSubmitHandler(e)}
                      >
                        {error ? <Alert color="danger">{error}</Alert> : null}
                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={(e) => inputChangeHandler(e)}
                            value={responseBody["email"]}
                          />
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Password</Label>
                          <Input
                            name="password"
                            onChange={(e) => inputChangeHandler(e)}
                            value={responseBody["password"]}
                            type="password"
                            placeholder="Enter Password"
                          />
                        </div>

                        <div className="row mb-4">
                          <div className="col">
                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                              >
                                Log In
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
