import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import NewAgencyForm from "./NewAgencyForm";
//import Breadcrumbs

const EditAgency = ({ match, location }) => {
  const {
    params: { agencyId },
  } = match;

  document.title = "Edit Agency | Agenxy";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title">Edit Agency</h4>
                </CardHeader>
                <CardBody className="p-4">
                  <NewAgencyForm data={{ id: agencyId }} dataLoaded={false} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditAgency;
