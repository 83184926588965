import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import NewContractForm from "./NewContractForm";
//import Breadcrumbs

const EditContract = ({ match, location }) => {
  const {
    params: { contractId },
  } = match;

  document.title = "Edit Contract | Agenxy";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title">Edit Contract</h4>
                </CardHeader>
                <CardBody className="p-4">
                  <NewContractForm
                    data={{ id: contractId }}
                    dataLoaded={false}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditContract;
