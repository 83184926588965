import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import NewContractForm from "./NewContractForm";
import { useHistory } from "react-router-dom";
//import Breadcrumbs

const DeleteContract = ({ match, location }) => {
  let history = useHistory();

  const {
    params: { contractId },
  } = match;
  useEffect(() => {
    async function deletePost() {
      await fetch("https://agenxyio.pythonanywhere.com/contract", {
        method: "DELETE",
        body: JSON.stringify({ id: contractId }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "x-api-key": `${
            JSON.parse(localStorage.getItem("authData"))["jwtLogin"]
          }`,
        },
      })
        .then((response) => response.json())
        .then(history.push("/all-contracts"));
    }
    deletePost();
  }, []);
};

export default DeleteContract;
