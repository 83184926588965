import React from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import NewTemplateForm from "./NewTemplateForm";
//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";

const newAgency = () => {
  document.title = "New Template | Agenxy";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title">New Template</h4>
                </CardHeader>
                <CardBody className="p-4">
                  <NewTemplateForm dataLoaded={true} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default newAgency;
