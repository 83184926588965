import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import NewTemplateForm from "./NewTemplateForm";
//import Breadcrumbs

const EditTemplate = ({ match, location }) => {
  const {
    params: { templateId },
  } = match;

  document.title = "Edit Template | Agenxy";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title">Edit Template</h4>
                </CardHeader>
                <CardBody className="p-4">
                  <NewTemplateForm
                    data={{ id: templateId }}
                    dataLoaded={false}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditTemplate;
