import React, { useState, useEffect } from "react";
import { Col, Form, Input, Label, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import Select from "react-select";

// Form Editor
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { act } from "react-dom/test-utils";

const NewContractForm = ({ data, dataLoaded }) => {
  let history = useHistory();
  const [fetchMethod, setFetchMethod] = useState("POST");
  const [responseBody, setResponseBody] = useState({
    type: {
      label: "Document",
      value: "Document",
    },
  });
  const [isDataLoaded, setDataLoaded] = useState(dataLoaded);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const fetchData = () => {
    fetch(`https://agenxyio.pythonanywhere.com/contract?id=${data["id"]}`, {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "x-api-key": `${
          JSON.parse(localStorage.getItem("authData"))["jwtLogin"]
        }`,
      },
    })
      .then((response) => response.json())
      .then((actualData) => {
        setResponseBody({ ...actualData });
        if (actualData.typevalue === "Document") {
          const contentBlock = htmlToDraft(actualData.document);
          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(
              contentBlock.contentBlocks
            );
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState);
          }
        }
      })
      .then((actualData) => setDataLoaded(true))
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    if (typeof data === "undefined") {
      setResponseBody({
        type: {
          label: "Document",
          value: "Document",
        },
      });
    } else {
      setFetchMethod("PUT");
      const timer = setTimeout(() => fetchData(), 2200);
      return () => {
        clearTimeout(timer);
      };
    }
  }, []);

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setResponseBody({ ...responseBody, [name]: value });
  };

  function onSubmitHandler(event) {
    event.preventDefault();
    fetch("https://agenxyio.pythonanywhere.com/contract", {
      method: fetchMethod,
      body: JSON.stringify({
        ...responseBody,
        document: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "x-api-key": `${
          JSON.parse(localStorage.getItem("authData"))["jwtLogin"]
        }`,
      },
    })
      .then((response) => response.json())
      .then(() => {
        setTimeout(() => {
          history.push("/all-contracts?wait=true");
        }, 1000); // 1000 milliseconds = 1 second
      });
  }

  if (isDataLoaded) {
    return (
      <React.Fragment>
        <Row>
          <Col className="ms-lg-auto">
            <div className="mt-4 mt-lg-0">
              <Form onSubmit={onSubmitHandler}>
                <Row className="mb-4">
                  <Label
                    htmlFor="horizontal-email-input"
                    className="col-sm-3 col-form-label"
                  >
                    Contract Type
                  </Label>
                  <Col sm={9}>
                    <Select
                      value={responseBody["type"]}
                      onChange={(e) => {
                        setResponseBody({ ...responseBody, type: e });
                      }}
                      name="type"
                      options={[
                        {
                          label: "Contract Type",
                          options: [
                            { label: "File", value: "File" },
                            {
                              label: "Document",
                              value: "Document",
                            },
                          ],
                        },
                      ]}
                      classNamePrefix="select2-selection"
                    />
                  </Col>
                </Row>

                <Row className="mb-4">
                  <Label
                    htmlFor="horizontal-name-input"
                    className="col-sm-3 col-form-label"
                  >
                    Name
                  </Label>
                  <Col sm={9}>
                    <Input
                      type="text"
                      className="form-control"
                      id="horizontal-name-input"
                      placeholder="Enter Name"
                      value={responseBody["name"]}
                      name="name"
                      onChange={(e) => inputChangeHandler(e)}
                      required
                    />
                  </Col>
                </Row>

                <Row className="mb-4">
                  <Label
                    htmlFor="horizontal-version-input"
                    className="col-sm-3 col-form-label"
                  >
                    Version
                  </Label>
                  <Col sm={9}>
                    <Input
                      type="text"
                      className="form-control"
                      id="horizontal-version-input"
                      placeholder="Enter Version"
                      value={responseBody["version"]}
                      name="version"
                      onChange={(e) => inputChangeHandler(e)}
                      required
                    />
                  </Col>
                </Row>

                <Row className="mb-4">
                  <Label
                    htmlFor="horizontal-image-input"
                    className="col-sm-3 col-form-label"
                  >
                    Image URL
                  </Label>
                  <Col sm={9}>
                    <Input
                      type="url"
                      className="form-control"
                      id="horizontal-image-input"
                      placeholder="Enter Image URL"
                      value={responseBody["imageURL"]}
                      name="imageURL"
                      onChange={(e) => inputChangeHandler(e)}
                      required
                    />
                  </Col>
                </Row>

                <Row className="mb-4">
                  <Label
                    htmlFor="horizontal-briefs-input"
                    className="col-sm-3 col-form-label"
                  >
                    Briefs
                  </Label>
                  <Col sm={9}>
                    <Input
                      type="text"
                      className="form-control"
                      id="horizontal-briefs-input"
                      placeholder="Enter Briefs"
                      value={responseBody["brief"]}
                      name="brief"
                      onChange={(e) => inputChangeHandler(e)}
                      required
                    />
                  </Col>
                </Row>

                <Row className="mb-4">
                  <Label
                    htmlFor="horizontal-description-input"
                    className="col-sm-3 col-form-label"
                  >
                    Description
                  </Label>
                  <Col sm={9}>
                    <textarea
                      rows={4}
                      className="form-control "
                      id="horizontal-description-input"
                      placeholder="Enter Description"
                      value={responseBody["description"]}
                      name="description"
                      onChange={(e) => inputChangeHandler(e)}
                      required
                    />
                  </Col>
                </Row>

                {responseBody?.type?.value == "File" ? (
                  <Row className="mb-4">
                    <Label
                      htmlFor="horizontal-file-input"
                      className="col-sm-3 col-form-label"
                    >
                      File URL
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="url"
                        className="form-control"
                        id="horizontal-file-input"
                        placeholder="Enter File URL"
                        value={responseBody["fileURL"]}
                        name="fileURL"
                        onChange={(e) => inputChangeHandler(e)}
                        required
                      />
                    </Col>
                  </Row>
                ) : (
                  <Row className="mb-4">
                    <Label
                      htmlFor="horizontal-file-input"
                      className="col-sm-3 col-form-label"
                    >
                      Document
                    </Label>
                    <Col sm={9}>
                      <Editor
                        editorState={editorState}
                        onEditorStateChange={onEditorStateChange}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                      />
                    </Col>
                  </Row>
                )}

                <Row className="justify-content-end">
                  <Col sm={9}>
                    <div>
                      <button type="reset" className="btn btn-secondary w-md">
                        Cancel
                      </button>

                      <button
                        type="submit"
                        style={{ marginLeft: "1.25%" }}
                        className="btn btn-primary w-md"
                      >
                        Submit
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  } else {
    <p>Please Wait While We are Rendering Data</p>;
  }
};

export default NewContractForm;
