import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "bootstrap/dist/css/bootstrap.min.css";
import DataTable from "../../components/DataTable";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Form,
  Input,
  Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import Select from "react-select";

let newData = [];
var dataCurrentMain;

function ProfileAffiliates({ match, location }) {
  const {
    params: { affiliateId },
  } = match;

  const [data, setData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState([]);
  const [responseBody, setResponseBody] = useState({
    userId: affiliateId,
    users: 0,
    other: "",
  });

  const fetchData = () => {
    fetch(
      `https://agenxyio.pythonanywhere.com/affiliate-users?id=${affiliateId}`,
      {
        method: "GET",
        headers: {
          "x-api-key": `${
            JSON.parse(localStorage.getItem("authData"))["jwtLogin"]
          }`,
        },
      }
    )
      .then((response) => response.json())
      .then((actualData) => {
        for (let i = 0; i < actualData.length; i++) {
          dataCurrentMain = actualData[i];
          newData.push(dataCurrentMain);
        }
        setData(newData);
        setDataLoaded(true);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    newData = [];
    const timer = setTimeout(() => fetchData(), 200);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setResponseBody({ ...responseBody, [name]: value });
  };

  function onSubmitHandler(event) {
    event.preventDefault();

    fetch("https://agenxyio.pythonanywhere.com/affiliate-users", {
      method: "POST",
      body: JSON.stringify({ ...responseBody, userId: affiliateId }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "x-api-key": `${
          JSON.parse(localStorage.getItem("authData"))["jwtLogin"]
        }`,
      },
    })
      .then((response) => response.json())
      .then((actualData) => {
        console.log(actualData);
        setData([...data, actualData["transactionData"]]);
        setResponseBody({
          userId: affiliateId,
          users: 0,
          other: "",
        });
      });
  }

  const columns = React.useMemo(() => [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "Amount",
      accessor: "amount",
    },
    {
      Header: "Payment Type",
      accessor: "type",
    },
    {
      Header: "Date",
      accessor: "date",
    },
    {
      Header: "Total User Signup",
      accessor: "users",
    },
    {
      Header: "Other Info",
      accessor: "other",
    },
  ]);

  //meta title
  document.title = "Profile Affiliates | Agenxy";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title">Affiliates Transaction</h4>
                </CardHeader>
                <CardBody className="p-4">
                  <Row>
                    <Col className="ms-lg-auto">
                      <div className="mt-4 mt-lg-0">
                        <Form onSubmit={onSubmitHandler}>
                          <Row className="mb-4">
                            <Label
                              htmlFor="horizontal-name-input"
                              className="col-sm-3 col-form-label"
                            >
                              Amount {"($)"}
                            </Label>
                            <Col sm={9}>
                              <Input
                                type="number"
                                className="form-control"
                                id="horizontal-name-input"
                                placeholder="Enter Amount ($)"
                                value={responseBody["amount"]}
                                name="amount"
                                onChange={(e) => inputChangeHandler(e)}
                                required
                              />
                            </Col>
                          </Row>

                          <Row className="mb-4">
                            <Label
                              htmlFor="horizontal-email-input"
                              className="col-sm-3 col-form-label"
                            >
                              Type
                            </Label>
                            <Col sm={9}>
                              <Select
                                value={responseBody["type"]}
                                onChange={(e) => {
                                  setResponseBody({ ...responseBody, type: e });
                                }}
                                name="type"
                                options={[
                                  {
                                    label: "Payment Type",
                                    options: [
                                      { label: "Add (+)", value: "Add" },
                                      {
                                        label: "Withdraw (-)",
                                        value: "Withdraw",
                                      },
                                    ],
                                  },
                                ]}
                                classNamePrefix="select2-selection"
                              />
                            </Col>
                          </Row>

                          <Row className="mb-4">
                            <Label
                              htmlFor="horizontal-date-input"
                              className="col-sm-3 col-form-label"
                            >
                              Select Transaction Date
                            </Label>
                            <Col sm={9}>
                              <Input
                                type="date"
                                className="form-control"
                                id="horizontal-date-input"
                                placeholder="Select Date"
                                value={responseBody["date"]}
                                name="date"
                                onChange={(e) => inputChangeHandler(e)}
                                required
                              />
                            </Col>
                          </Row>

                          <Row className="mb-4">
                            <Label
                              htmlFor="horizontal-users-input"
                              className="col-sm-3 col-form-label"
                            >
                              Add No. of Users Converted
                            </Label>
                            <Col sm={9}>
                              <Input
                                type="number"
                                className="form-control"
                                id="horizontal-users-input"
                                placeholder="Enter No. of Users Converted"
                                value={responseBody["users"]}
                                name="users"
                                onChange={(e) => inputChangeHandler(e)}
                                required
                                min={0}
                              />
                            </Col>
                          </Row>

                          <Row className="mb-4">
                            <Label
                              htmlFor="horizontal-other-input"
                              className="col-sm-3 col-form-label"
                            >
                              Other information
                            </Label>
                            <Col sm={9}>
                              <Input
                                type="text"
                                className="form-control"
                                id="horizontal-other-input"
                                placeholder="Other information"
                                value={responseBody["other"]}
                                name="other"
                                onChange={(e) => inputChangeHandler(e)}
                              />
                            </Col>
                          </Row>

                          <Row className="justify-content-end">
                            <Col sm={9}>
                              <div>
                                <button
                                  type="reset"
                                  className="btn btn-secondary w-md"
                                >
                                  Cancel
                                </button>

                                <button
                                  type="submit"
                                  style={{ marginLeft: "1.25%" }}
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title">Affiliates Transaction</h4>
                </CardHeader>
                <CardBody className="p-4">
                  {dataLoaded ? (
                    <DataTable columns={columns} data={data} />
                  ) : (
                    "Data Is Rendering"
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
ProfileAffiliates.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default ProfileAffiliates;
