import React from "react";
import { Redirect } from "react-router-dom";

//Dashboard
import Dashboard from "../pages/Dashboard/index";
import NewAgency from "../pages/superAdmin/NewAgency";
import EditAgency from "../pages/superAdmin/EditAgency";
import AllAgencys from "../pages/superAdmin/AllAgencys";
import DeleteAgency from "../pages/superAdmin/DeleteAgency";

import AllTemplates from "../pages/superAdmin/AllTemplates";
import NewTemplate from "../pages/superAdmin/NewTemplate";
import EditTemplate from "../pages/superAdmin/EditTemplate";
import DeleteTemplate from "../pages/superAdmin/DeleteTemplate";

import AllContracts from "../pages/superAdmin/AllContracts";
import NewContract from "../pages/superAdmin/NewContract";
import EditContract from "../pages/superAdmin/EditContract";
import DeleteContract from "../pages/superAdmin/DeleteContract";

// Affiliates
import NewAffiliates from "../pages/superAdmin/NewAffiliates";
import AllAffiliates from "../pages/superAdmin/AllAffiliates";
import EditAffiliate from "../pages/superAdmin/EditAffiliate";
import DeleteAffiliate from "../pages/superAdmin/DeleteAffiliate";
import ProfileAffiliates from "../pages/superAdmin/ProfileAffiliates";

// Authentication related pages
import LoginRedirect from "../pages/Authentication/LoginRedirect";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import userProfile from "../pages/Authentication/user-profile";
import { isCompositeComponent } from "react-dom/test-utils";

const userRoutes = [
  //dashboard
  { path: "/dashboard", component: Dashboard },
  { path: "/new-agency", component: NewAgency },
  { path: "/edit-agency/:agencyId", component: EditAgency },
  { path: "/all-agencys", component: AllAgencys },
  {
    path: "/delete-agency/:agencyId",
    component: DeleteAgency,
  },
  { path: "/new-template", component: NewTemplate },
  {
    path: "/edit-template/:templateId",
    component: EditTemplate,
  },
  {
    path: "/delete-template/:templateId",
    component: DeleteTemplate,
  },
  { path: "/all-templates", component: AllTemplates },
  { path: "/new-contract", component: NewContract },
  {
    path: "/edit-contract/:contractId",
    component: EditContract,
  },
  {
    path: "/delete-contract/:contractId",
    component: DeleteContract,
  },
  { path: "/all-contracts", component: AllContracts },

  { path: "/new-affiliate", component: NewAffiliates },
  {
    path: "/edit-affiliate/:affiliateId",
    component: EditAffiliate,
  },
  {
    path: "/delete-affiliate/:affiliateId",
    component: DeleteAffiliate,
  },
  { path: "/all-affiliates", component: AllAffiliates },
  { path: "/profile-affiliate/:affiliateId", component: ProfileAffiliates },

  { path: "/profile", component: userProfile },

  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/login-redirect" />,
  },
];
const authRoutes = [
  //authencation page
  { path: "/logout", component: Logout },
  { path: "/login-redirect", component: LoginRedirect },
  { path: "/login", component: Login },
];

export { userRoutes, authRoutes };
