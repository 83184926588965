import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "bootstrap/dist/css/bootstrap.min.css";
import DataTable from "../../components/DataTable";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";

let newData = [];
var dataCurrentMain;

function AllAgencys() {
  const [data, setData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState([]);

  const fetchData = () => {
    fetch(`https://agenxyio.pythonanywhere.com/agency`, {
      method: "GET",
      headers: {
        "x-api-key": `${
          JSON.parse(localStorage.getItem("authData"))["jwtLogin"]
        }`,
      },
    })
      .then((response) => response.json())
      .then((actualData) => {
        for (let i = 0; i < actualData.length; i++) {
          dataCurrentMain = actualData[i];
          dataCurrentMain["edit"] = (
            <Link
              to={"/edit-agency/" + dataCurrentMain["id"]}
              className="btn btn-sm btn-outline-success w-md"
            >
              <i className="mdi mdi-pencil-outline "></i>Edit
            </Link>
          );

          dataCurrentMain["delete"] = (
            <Link
              to={"/delete-agency/" + dataCurrentMain["id"]}
              className="btn btn-sm btn-outline-danger w-md"
            >
              <i className="mdi mdi-trash-can-outline"></i>Delete
            </Link>
          );
          newData.push(dataCurrentMain);
        }
        setData(newData);
        setDataLoaded(true);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    newData = [];
    const timer = setTimeout(() => fetchData(), 200);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  // useEffect(() => {
  //   let interval = setInterval(, )
  //   fetchData();
  // }, []);

  const columns = React.useMemo(() => [
    {
      Header: "Id",
      accessor: "index",
    },
    {
      Header: "Name",
      accessor: "agencyName",
    },
    {
      Header: "Owner Name",
      accessor: "agencyOwnerName",
    },

    {
      Header: "Registration Date",
      accessor: "registrationTimestamp",
    },

    {
      Header: "E-mail",
      accessor: "email",
    },

    {
      Header: "Owner Email",
      accessor: "agencyOwnerEmail",
    },

    {
      Header: "Edit",
      accessor: "edit",
    },
    {
      Header: "Delete",
      accessor: "delete",
    },
  ]);

  //meta title
  document.title = "All Agencys | Agenxy";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row>
                    <Col sm="6">
                      <h4 className="card-title">All Agencies</h4>
                    </Col>
                    <Col sm="6">
                      <div className="text-sm-end">
                        <Link
                          to={"/new-agency"}
                          className="btn btn-sm btn-secondary w-md"
                        >
                          New Agency
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-4">
                  {dataLoaded ? (
                    <DataTable
                      columns={columns}
                      data={data}
                      isAddInvoiceList={true}
                      customPageSize={10}
                    />
                  ) : (
                    "Data Is Rendering"
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
AllAgencys.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default AllAgencys;
