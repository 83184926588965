import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

//i18n
import { withTranslation } from "react-i18next";
import SidebarContent from "./SidebarContent";
import SidebarAdminContent from "./SidebarAdminContent";
import SidebarAgencyContent from "./SidebarAgencyContent";
import SidebarCustomerContent from "./SidebarCustomerContent";
import SidebarAgencyPayment from "./SidebarAgencyPayment";
var userType = "";
var userPlan = "";

const Sidebar = (props) => {
  let sidebarData = "";

  userType = `${JSON.parse(localStorage.getItem("authData"))["role"]}`;
  userPlan = `${JSON.parse(localStorage.getItem("authData"))?.plan}`;
  //
  if (
    userType === "agency" &&
    props.type !== "condensed" &&
    userPlan == "Inactive"
  ) {
    sidebarData = <SidebarAgencyPayment />;
  } else if (
    userType === "agency" &&
    props.type !== "condensed" &&
    userPlan != "Inactive"
  ) {
    sidebarData = <SidebarAgencyContent />;
  } else if (userType === "admin" && props.type !== "condensed") {
    sidebarData = <SidebarAdminContent />;
  } else if (userType === "customer" && props.type !== "condensed") {
    sidebarData = <SidebarCustomerContent />;
  }
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div data-simplebar className="h-100">
          {sidebarData}
        </div>
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
};

const mapStatetoProps = (state) => {
  return {
    layout: state.Layout,
  };
};
export default connect(mapStatetoProps, {})(withTranslation()(Sidebar));
