import { useHistory } from "react-router-dom";
import React, { useEffect } from "react";

const LoginRedirect = () => {
  let history = useHistory();

  if (JSON.parse(localStorage.getItem("authData"))) {
    const authDataUser = JSON.parse(localStorage.getItem("authData"));
    if (authDataUser?.role) {
      if (authDataUser["role"] === "admin") {
        history.push("/all-agencys");
      } else {
        localStorage.removeItem("authData");
        history.push("/login");
      }
    } else {
      localStorage.removeItem("authData");
      history.push("/login");
    }
  } else {
    history.push("/login");
  }
};

export default LoginRedirect;

// `${JSON.parse(localStorage.getItem("authData"))["jwtLogin"]}`
