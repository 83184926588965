import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "bootstrap/dist/css/bootstrap.min.css";
import DataTable from "../../components/DataTable";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";

let newData = [];
var dataCurrentMain;

function AllAffiliates() {
  const [data, setData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState([]);

  const fetchData = () => {
    fetch(`https://agenxyio.pythonanywhere.com/affiliate`, {
      method: "GET",
      headers: {
        "x-api-key": `${
          JSON.parse(localStorage.getItem("authData"))["jwtLogin"]
        }`,
      },
    })
      .then((response) => response.json())
      .then((actualData) => {
        for (let i = 0; i < actualData.length; i++) {
          dataCurrentMain = actualData[i];
          dataCurrentMain["profile"] = (
            <Link
              to={"/profile-affiliate/" + dataCurrentMain["id"]}
              className="btn btn-sm btn-outline-success w-md"
            >
              <i className="mdi mdi-account-outline "></i>profile
            </Link>
          );
          newData.push(dataCurrentMain);
        }
        setData(newData);
        setDataLoaded(true);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    newData = [];
    const timer = setTimeout(() => fetchData(), 200);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const columns = React.useMemo(() => [
    {
      Header: "Id",
      accessor: "index",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "E-mail",
      accessor: "email",
    },
    {
      Header: "Code",
      accessor: "code",
    },
    {
      Header: "Total Earning",
      accessor: "totalEarning",
    },
    {
      Header: "Total User Signup",
      accessor: "totaluser",
    },
    {
      Header: "Join Date",
      accessor: "joinDate",
    },
    {
      Header: "Last Date",
      accessor: "lastUpdate",
    },
    {
      Header: "Profile",
      accessor: "profile",
    },
  ]);

  //meta title
  document.title = "All Affiliates | Agenxy";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title">All Affiliates</h4>
                </CardHeader>
                <CardBody className="p-4">
                  {dataLoaded ? (
                    <DataTable columns={columns} data={data} />
                  ) : (
                    "Data Is Rendering"
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
AllAffiliates.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default AllAffiliates;
